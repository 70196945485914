<script setup lang="ts">
import ThinkiLogoSVG from '@/assets/icons/favicon.svg';
import { UserPrivilegeEnum } from '@/api/users';
import useLoginManager from '@/services/loginManager';
import { computed } from 'vue';
import { useRouter } from 'vue-router';

const router = useRouter();
const loginManager = useLoginManager();

const iAmSuperAdmin = computed(() => loginManager.hasPrivilege(UserPrivilegeEnum.SUPERADMIN));
const iAmDeveloper = computed(() => loginManager.hasPrivilege(UserPrivilegeEnum.DEVELOPER));

function onAvatarClick() {
    const userId = loginManager.me.value?.id;
    if (userId) {
        router.push({ name: 'user-card', params: { id: userId, tab: 'detail' } });
    } else {
        console.error('User ID is undefined');
    }
}

</script>

<template>
    <!-- USER -->
    <v-list>
        <v-list-item @click="onAvatarClick" :prepend-avatar="ThinkiLogoSVG" :title="loginManager.me.value?.name"
            density="compact">
            <v-list-item-subtitle class="text-small">
                {{ loginManager.me.value?.email }}
            </v-list-item-subtitle>
        </v-list-item>
    </v-list>

    <v-divider></v-divider>

    <!-- MAIN MENU -->
    <v-list nav density="compact" :lines="false">
        <v-list-item prepend-icon="mdi-view-dashboard" title="Dashboard" to="/"></v-list-item>
        <v-list-item v-if="iAmSuperAdmin" prepend-icon="mdi-human-male-female-child" title="Customers"
            to="/customer"></v-list-item>
        <v-list-item v-if="iAmSuperAdmin" prepend-icon="mdi-baby-face-outline" title="Devices"
            to="/devices"></v-list-item>
        <v-list-item v-if="iAmSuperAdmin" prepend-icon="mdi-account-group-outline" title="Users"
            to="/users"></v-list-item>

        <!-- SYSTEM -->
        <v-list-group v-if="iAmSuperAdmin" value="System">
            <template v-slot:activator="{ props }">
                <v-list-item v-bind="props" prepend-icon="mdi-cog-outline" title="System"></v-list-item>
            </template>

            <v-list-item v-if="iAmSuperAdmin" class="pl-0" prepend-icon="mdi-security" title="Roles"
                to="/roles"></v-list-item>
            <v-list-item v-if="iAmSuperAdmin" class="pl-0" prepend-icon="mdi-format-list-numbered" title="Device type"
                to="/devices/type"></v-list-item>
            <v-list-item v-if="iAmSuperAdmin" class="pl-0" prepend-icon="mdi-email-fast-outline" title="Mail log"
                to="/mail-log"></v-list-item>
        </v-list-group>
    </v-list>
</template>