import { HTTP } from '@/services/http'
import { type DRFOptionsResponse, type PaginatedType } from '@/utils/DjangoTypes'
import { type ServerTableParamsType, getServerTableQuery } from '@/utils/TableUtils'

function randomPin() {
  return Math.floor(100000 + Math.random() * 900000).toString()
}

export enum UserPrivilegeEnum {
  LOGIN = 'LOGIN',
  DEVELOPER = 'DEVELOPER',
  ADMIN = 'ADMIN',
  USER = 'USER',
  SUPERADMIN = 'SUPERADMIN',
}

export interface UserType {
  id: number
  types: Array<string>
  is_active: boolean
  email: string
  phone: string
  full_name: string
  sex: string
  family: Array<number>
  relationship_to_child: string
  roles: Array<number>
  readonly privileges?: string[]
}

export const DefaultUserType = Object.freeze<UserType>({
  id: 0,
  types: [],
  is_active: false,
  email: '',
  phone: '',
  full_name: '',
  sex: '',
  family: [],
  relationship_to_child: '',
  roles: [],
})

export interface ImpersonationCodeResponse {
  code: string
  portalLink: string
}

export default {
  async getAllServerTable(stParams: ServerTableParamsType) {
    return await HTTP.get<PaginatedType<Array<UserType>>>('/user/', {
      params: {
        ...getServerTableQuery(stParams)
      }
    })
  },

  async getAllUsersType() {
    return await HTTP.get<Array<UserType>>("/user/");
  },

  async getModelInfo() {
    return await HTTP.options<DRFOptionsResponse>('/user/')
  },

  async save(id: number, userType: Partial<UserType>) {
    if (!id) {
      return await HTTP.post<UserType>('/user/', userType)
    }
    return await HTTP.patch<UserType>(`/user/${id}/`, userType)
  },

  async delete(id: number) {
    return await HTTP.delete<UserType>(`/user/${id}/`)
  },

  async get(id: number) {
    return await HTTP.get<UserType>(`/user/${id}/`)
  },

  async getFiltered(
    stParams: ServerTableParamsType,
    active: boolean | null = null,
    hasPrivileges: string[] | null = null
  ) {
    const extraFilters: Record<string, string> = {}

    // query filters
    if (active !== null) {
      stParams.filter['is_active'] = active ? 'true' : 'false'
    }

    if (hasPrivileges !== null) {
      extraFilters['hasPrivileges'] = hasPrivileges.join(',')
    }

    return await HTTP.get<PaginatedType<Array<UserType>>>('/user/', {
      params: {
        ...extraFilters,
        ...getServerTableQuery(stParams)
      }
    })
  },

  async me() {
    return await HTTP.get<UserType>(`/me/`)
  },

  async invite(id: number) {
    return await HTTP.post(`/user/${id}/send/invite/`)
  },

  async resetPassword(id: number) {
    return await HTTP.post(`/user/${id}/send/password-reset/`)
  },

  async getPortalImpersonateLink(id: number) {
    return await HTTP.post<ImpersonationCodeResponse>(`/user/${id}/impersonate/`)
  }
}
